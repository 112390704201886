import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  outOfService: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    textAlign: "left",
    width: "90vw",
    background: "#f5f6f8",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "15px",
    borderRadius: "15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.primary.main,
    maxWidth: "350px",
  },
  title: {
    margin: "0 0 15px",
    padding: "0",
  },
  text: {
    margin: "0",
    padding: "0",
  },
  link: {
    color: "#E64B37",
  },
}));

import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Link, Typography } from "@material-ui/core";
import queryString from "query-string";

import useStyles from "./styles/outOfService";

// dispensary slug to use if partner not specified in search params (and during SSR)
const fallbackDispensarySlug = "wellworld-incorrect-dispensary-url";

const slugify = (slugToBe) =>
  slugToBe
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

const lastAndFirstNameRegex = /^([A-Za-z]+)\s*,\s*([A-Za-z]+)$/;

const normalizePartnerCodeForSlugify = (partnerCode) => {
  const lastFirstNameMatch = partnerCode.match(lastAndFirstNameRegex);
  if (!lastFirstNameMatch) {
    return partnerCode;
  }
  const [, lastName, firstName] = lastFirstNameMatch;
  return `${firstName} ${lastName}`;
};

/**
 * Generate slug from partner code using algorithm from NetSuite map/reduce script
 */
const slugifyPartnerCode = (code) =>
  slugify(normalizePartnerCodeForSlugify(code));

const OutOfService = () => {
  const classes = useStyles();
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsSSR(false);
    }
  }, []);

  const params = useMemo(() => {
    return isSSR ? {} : queryString.parse(window.location.search);
  }, [isSSR]);
  const href = useMemo(() => {
    const { partner, ...restParams } = params;
    const dispensarySlug = partner
      ? slugifyPartnerCode(partner)
      : fallbackDispensarySlug;
    const search = queryString.stringify(restParams);
    const searchSuffix = search ? `?${search}` : "";
    return `https://www.designsforhealth.com/u/${dispensarySlug}/checkout/${searchSuffix}`;
  }, [params]);
  return (
    <>
      <Helmet>
        <title>Out of Service</title>
      </Helmet>
      <div className={classes.outOfService}>
        <h1 className={classes.title}>
          Access our new shopping cart via the button below.
        </h1>
        <Typography variant="body2" gutterBottom>
          This shopping cart is no longer active. If your recommended
          supplements do not automatically populate in the new shopping cart,
          please ask your practitioner for a new link.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Contact our Customer Service for any questions you might have:{" "}
          <Link className={classes.link} href="tel:+18606236314">
            860-623-6314
          </Link>
        </Typography>
        <Box mt={8}>
          <Button href={href} variant="contained" color="primary" fullWidth>
            Checkout here!
          </Button>
        </Box>
      </div>
    </>
  );
};

export default OutOfService;
